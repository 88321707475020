import MyAccountModal from "@components/myAccountModal"
import ChangePasswordForm from "@modules/Auth/Site/components/ChangePasswordForm"
import React, { useEffect, useState } from "react"

const ChangePassword = () => {
  const [visible, setVisible] = useState(true)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleClose = () => {
    setVisible(false)
  }

  if (!isClient) return null

  return (
    <MyAccountModal visible={visible} handleClose={handleClose}>
      <ChangePasswordForm />
    </MyAccountModal>
  )
}

export default ChangePassword
